export const SERVICE_CONFIG: Object = {
  'users': {
    'path': '/users'
  },
  'master': {
    'path': '/master'
  },
  'doctor': {
    'path': '/doctor'
  },
  'products': {
    'path': '/products'
  },
  'mailing': {
    'path': '/mailing'
  },
  'documents': {
    'path': '/documents'
  },
  permissions: {
    'path': '/permissions'
  }
};
